import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = ["input", "results", "btn", "groupbtn"];

  connect() {
    this.inputTarget.addEventListener("input", this.fetchResults.bind(this));
    this.inputTarget.addEventListener("click", this.preventBootstrapDefault.bind(this));
    this.inputTarget.addEventListener("click", this.closeDropdown.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.closeDropdown.bind(this));
  }

  preventBootstrapDefault(event) {
    event.preventDefault();
    this.hideDropdown();
  }

  fetchResults() {
    const query = this.inputTarget.value.trim();
    if (query.length === 0) {
      this.hideDropdown();
      return;
    }

    fetch(`/${this.data.get("queryType")}/search?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          this.resultsTarget.innerHTML = data
            .map(
              user =>
                `<li><a href="#" class="dropdown-item" data-action="click->autocomplete#selectUser">${user}</a></li>`
          )
          .join("");
        this.showDropdown();
      } else {
        this.hideDropdown();
      }
    })
    .catch(error => {
      console.error("Error fetching autocomplete data:", error);
      this.hideDropdown();
    });
  }

  selectUser(event) {
    event.preventDefault(); // Prevent default anchor behavior
    const query = event.target.textContent;
    this.inputTarget.value = query;
    this.hideDropdown(); // Close dropdown after selection

    if (this.data.get("queryType") === "chats" && !query.includes("@")) {
      if (this.groupbtnTarget) {
        this.groupbtnTarget.click();
      }
    } else {
      if (this.btnTarget) {
        this.btnTarget.click();
      }
    }
  }

  showDropdown() {
    this.resultsTarget.classList.add("show");
  }

  hideDropdown() {
    this.resultsTarget.classList.remove("show");
  }

  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.hideDropdown();
    }
  }
}
