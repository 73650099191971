import { Controller } from "@hotwired/stimulus";
import Trix from "trix";
import Rails from "@rails/ujs";
import "emoji-picker-element";

let lang = Trix.config.lang;

export default class extends Controller {

	connect() {
		Trix.config.lang.table = "Table"
		var tableButtonHTML = `<button type="button" class="trix-button trix-button--icon trix-button--icon-table" data-action="rich-text#attachTable" title="Attach Table" tabindex="-1">${lang.table}</button>`;
		var emojiButtonHTML = `<button type="button" class="trix-button trix-button--icon trix-button--icon-smiley" data-action="rich-text#toggleEmojiPicker" title="Insert Emoji" tabindex="-1">${lang.table}</button>`;
    var emojiPickerHTML = `<emoji-picker style="display: none; position: absolute; z-index: 10; margin-top: 30px;" "data-rich-text-target="emojiPicker"></emoji-picker>`;
    var fileToolsElement = this.element.querySelector('[data-trix-button-group=file-tools]');
    if (!fileToolsElement) {
      setTimeout(() => {
        fileToolsElement = this.element.querySelector('[data-trix-button-group=file-tools]');
        fileToolsElement.insertAdjacentHTML("beforeend", tableButtonHTML);
        fileToolsElement.insertAdjacentHTML("beforeend", emojiButtonHTML);
        fileToolsElement.insertAdjacentHTML("beforeend", emojiPickerHTML);
        const emojiPicker = this.element.querySelector("emoji-picker");
        emojiPicker.addEventListener("emoji-click", this.insertEmoji.bind(this));
      }, 1000);
    } else {
      fileToolsElement.insertAdjacentHTML("beforeend", tableButtonHTML);
      fileToolsElement.insertAdjacentHTML("beforeend", emojiButtonHTML);
      fileToolsElement.insertAdjacentHTML("beforeend", emojiPickerHTML);
      const emojiPicker = this.element.querySelector("emoji-picker");
      emojiPicker.addEventListener("emoji-click", this.insertEmoji.bind(this));
    }
	}

	attachTable(event) {
    const tableData = document.querySelector("#tables-data");
		Rails.ajax({
			url: `/tables`,
			type: 'post',
      data: `record_type=${tableData.dataset.recordType}&record_id=${tableData.dataset.recordId}`,
			success: this.insertTable.bind(this)
		});
	}

	insertTable(tableAttachment) {
		this.attachment = new Trix.Attachment(tableAttachment);
		this.element.querySelector('trix-editor').editor.insertAttachment(this.attachment);
		this.element.focus();
	}

  toggleEmojiPicker(event) {
    const picker = this.element.querySelector("emoji-picker");
    if (!picker) return;
    const isVisible = picker.style.display === "block";
    if (isVisible) {
      picker.style.display = "none";
    } else {
      picker.style.display = "block";
      const leftPosition = (window.innerWidth - picker.offsetWidth) / 2;
      picker.style.left = `${leftPosition}px`;
    }
  }

  insertEmoji(event) {
    const picker = this.element.querySelector("emoji-picker");
    const emoji = event.detail.unicode;
    const editor = this.element.querySelector("trix-editor").editor;
    if (editor) {
      editor.insertString(emoji);
    }
    picker.style.display = "none";
  }
}
