import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// Connects to data-controller="toggle-admin-only-view"
export default class extends Controller {
  static targets = ["toggle", "submit", "query", "admins"];

  connect() {
    const params = new URLSearchParams(window.location.search);

    if (params.get("view_admins") === "1") {
      this.toggleTarget.checked = true;
      this.adminsTarget.value = "1";
    }

    const searchQuery = params.get("search");
    if (searchQuery) {
      this.queryTarget.value = searchQuery;
    }
  }

  toggle() {
    Rails.fire(this.submitTarget, "submit");
  }
}
